import React, { useState, useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import styled from "styled-components";
import familyFull from "../../static/images/bankruptcy-assets/familyFull.jpeg";
import familyMobile from "../../static/images/bankruptcy-assets/family-mobile.png";
import handMockup from "../../static/images/bankruptcy-assets/hand2.png";
import handMobile from "../../static/images/bankruptcy-assets/handMobile.png";
import wsj from "../../static/images/bankruptcy-assets/wsj.png";
import wpost from "../../static/images/bankruptcy-assets/wpost.png";
import nytimes from "../../static/images/bankruptcy-assets/nytimes.png";
import time from "../../static/images/bankruptcy-assets/time.png";
import fastcompany from "../../static/images/bankruptcy-assets/fastcompany.png";
import forbes from "../../static/images/bankruptcy-assets/forbes.png";
import ReactPlayer from "react-player";
import { useWindowSize } from "react-use";
import IconCaret from "../../static/images/bankruptcy-assets/chevron-down.svg";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import upsolveJSONLD from "../components/SEO/upsolveJSONLD";
import webApplicationJSONLD from "../components/SEO/webApplicationJSONLD";
import SEO from "../components/SEO/SEO";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import getMyUpsolveEntryUrl from "../components/Links/getMyUpsolveEntryUrl";
import { CTATrackingTarget, track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import { EVENTS } from "@upsolve/shared";
import { CTATarget } from "../components/CTA/getCTAData";
import { ReviewsWall } from "../components/reviews/ReviewsWall";

const contentGroup = CONTENT_GROUPS.BANKRUPTCY_PRODUCT_PAGE;
const templateProps = {
  pageComponentName: "BankruptcyProductPage",
  pageComponentVersion: "5",
  contentGroup,
  searchTermTargets: ["upsolve"],
};
const trackingPageContext = {
  ...templateProps,
};
// should we add a Community link? might help with trust?
export default function BankruptcyProductPage() {
  const { width } = useWindowSize();
  const isMobile = width < 940;

  useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);
  return (
    <TopLevelLayout showPersistentCta={false}>
      <div className="index-container">
        <SEO
          title="File Bankruptcy for Free | TurboTax for Bankruptcy | Nonprofit"
          description="Upsolve is America's #1 source for unbiased debt relief help and information. Donor-funded and featured 4x in Forbes."
          image="https://static.upsolve.org/app-preview-bubble.png"
          url="https://upsolve.org/bankruptcy"
          jsonLD={[upsolveJSONLD, webApplicationJSONLD]}
        />
        <Header allowMobileFooter={false} trackingPageContext={trackingPageContext} ctaTarget={CTATarget.SCREENER} />
        <ProductPage>
          <section className="hero">
            <div className="hero__content content">
              <div className="hero__card">
                <h1>
                  Eliminate Your Debt
                  <br />
                  With Bankruptcy
                </h1>
                <p>Use our nonprofit app to prepare your forms, for free.</p>
                <div className="hero__card__buttons">
                  <ScreenerButton /> <SignInButton />
                </div>
              </div>
              <div className="hero__members-number">
                <h1>Join 13,000 members</h1>
                <p>who eliminated their debts with our help</p>
              </div>
              <div className="hero__badges-container">
                <div className="hero__badges">{badges.map((badge) => badge)}</div>
              </div>
            </div>
          </section>
          <section className="how-it-works">
            <div className="how-it-works__content content">
              <h1 className="how-it-works__header-mobile hide-widescreen">
                You could be debt-free in as soon as <span className="blue">4 months.</span>
              </h1>
              <div className="how-it-works__hand-mockup--container">
                <div className="how-it-works__hand-mockup" />
              </div>
              <div className="how-it-works__timeline-section">
                <div className="how-it-works__timeline">
                  <h1 className="how-it-works__header hide-mobile">
                    You could be debt-free in as soon as <span className="blue">4 months.</span>
                  </h1>
                  <p>Here's how long each part of the process takes.</p>
                  <div className="how-it-works__timeline__step">
                    <p>
                      <b>1.</b>
                    </p>
                    <p>
                      <b>Take a screener</b> to see if Upsolve is right for you: 2 minutes
                    </p>
                  </div>
                  <div className="how-it-works__timeline__step">
                    <p>
                      <b>2.</b>
                    </p>
                    <p>
                      <b>Fill out a questionnaire</b> about your financial situation: a few hours
                    </p>
                  </div>
                  <div className="how-it-works__timeline__step">
                    <p>
                      <b>3.</b>
                    </p>
                    <p>
                      <b>File your forms</b> at your local court: 1 afternoon
                    </p>
                  </div>
                  <div className="how-it-works__timeline__step">
                    <p>
                      <b>4.</b>
                    </p>
                    <p>
                      <b>Work with the court:</b> 1-2 months
                    </p>
                  </div>
                  <div className="how-it-works__timeline__step">
                    <p>
                      <b>5.</b>
                    </p>
                    <p>
                      <b>Await your debt discharge:</b> 2-3 months
                    </p>
                  </div>
                  <div className="hide-mobile">
                    <ScreenerButton />
                  </div>
                </div>
              </div>
              <div className="hide-widescreen">
                <ScreenerButton />
              </div>
            </div>
          </section>
          <section className="trust">
            <div className="trust__content content">
              <h1>Why Trust Upsolve?</h1>
              <h3 className="trust__content-reasons">
                <b>You're in control: </b>We explain exactly what info you need for your forms, and why. You're in full
                control of your information at every step of the way.
                <br />
                <br />
                <b>Privacy: </b>We keep your data safe and secure. It only leaves our servers when you print out your
                forms.
                <br />
                <br />
                <b>Affordability:</b> Upsolve is a nonprofit, and our tools are 100% free.
              </h3>
              <ReviewsWall />
            </div>
          </section>
          <section className="faq">
            <div className="faq__content content">
              <h1 className="faq__content__header">Is Upsolve Right for You?</h1>
              <FAQ />
            </div>
          </section>
          <section className="video">
            <div className="video__content content">
              <h1>How does Upsolve work?</h1>
              <ReactPlayer
                width={isMobile ? "350px" : "850px"}
                height={isMobile ? "197px" : "480px"}
                url={"https://www.youtube.com/watch?v=4FCf9FC_1nI"}
                controls={true}
              />

              <ScreenerButton />
            </div>
          </section>
        </ProductPage>
        <Footer bio={false} />
      </div>
    </TopLevelLayout>
  );
}
const HomePageFAQItem = ({ question, answer }: { question: string; answer: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="faq__question" onClick={() => setIsOpen(!isOpen)}>
      <div className={`faq__question__header ${isOpen ? "opened" : "closed"}`}>
        <div>{question}</div> <IconCaret />
      </div>
      {isOpen && <div>{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const { faq } = useStaticQuery(graphql`
    query BkFAQQuery {
      faq: contentfulFaqHcj(title: { eq: "bankruptcy-product-page" }) {
        questions {
          id
          text {
            text
          }
          answers {
            id
            text {
              text
            }
          }
        }
      }
    }
  `);

  const questions: { question: string; answer: string }[] = faq?.questions?.map((q: any) => {
    return { question: q.text.text, answer: richTextNodeToComponent(JSON.parse(q.answers[0].text.text)) };
  });
  return (
    <StyledHomePageFAQ>
      {questions?.map((question, index) => (
        <HomePageFAQItem key={index} question={question.question} answer={question.answer} />
      ))}
    </StyledHomePageFAQ>
  );
};

const StyledHomePageFAQ = styled.div`
  width: 100%;
  max-width: 633px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13.333px;
  @media screen and (max-width: 940px) {
    max-width: 340px;
  }
  .faq__question {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 6.7px;
    border: 0.667px solid var(--Gray-3, #828282);
    width: 100%;
    padding: 15px 13.333px;
    gap: 15px;
    box-sizing: border-box;
    cursor: pointer;
    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 18.667px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      svg {
        transition: 250ms;
      }
      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    &__text {
      ul {
        list-style-type: initial;
      }
    }
  }
`;

const ForbesLogo = styled.a`
  background: url("${forbes}");
  width: 68px;
  height: 28.667px;
  flex-shrink: 0;
`;
const WSJLogo = styled.a`
  background: url("${wsj}");
  width: 211.333px;
  height: 35.333px;
  flex-shrink: 0;
`;
const WPLogo = styled.a`
  background: url("${wpost}");
  width: 194px;
  height: 18.667px;
  flex-shrink: 0;
`;
const NYTLogo = styled.a`
  background: url("${nytimes}");
  width: 180.667px;
  height: 26px;
  flex-shrink: 0;
`;
const FCLogo = styled.a`
  background: url("${fastcompany}");
  width: 111.03px;
  height: 16px;
  flex-shrink: 0;
`;
const TimeLogo = styled.a`
  background: url("${time}");
  width: 56.133px;
  height: 70.167px;
  flex-shrink: 0;
`;
const badges = [
  <TimeLogo target="_blank" href="https://time.com/collection/best-inventions-2020/5911367/upsolve/" />,
  <WSJLogo
    target="_blank"
    href="https://www.wsj.com/articles/for-struggling-consumers-a-cheaper-way-to-file-for-bankruptcy-1505096071"
  />,
  <NYTLogo target="_blank" href="https://www.nytimes.com/2018/12/21/technology/year-in-technology-2018.html" />,
  <ForbesLogo
    target="_blank"
    href="https://www.forbes.com/sites/chrisdenhart/2018/09/19/upsolve-moves-upward-under-30-cofounders-bankruptcy-service-is-just-one-click-away/#2d4916916747"
  />,
  <FCLogo target="_blank" href="https://www.fastcompany.com/90457894/not-for-profit-most-innovative-companies-2020" />,
  <WPLogo
    target="_blank"
    href="https://www.washingtonpost.com/outlook/what-happens-when-you-cant-afford-to-go-bankrupt/2018/03/02/343fd882-1d8e-11e8-9de1-147dd2df3829_story.html"
  />,
];

const ScreenerButton = () => {
  const onClickScreenerButton = () => {
    track(EVENTS.COMPONENT_ACTION, {
      actionId: "bankruptcyProductPage.takeScreener.myUpsolveNavigation",
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
    navigate(getMyUpsolveEntryUrl({ triggeredByActionId: "bankruptcyProductPage.takeScreener.myUpsolveNavigation" }));
  };
  return (
    <div>
      <StyledButton onClick={onClickScreenerButton}>Take Screener</StyledButton>
    </div>
  );
};
const SignInButton = () => {
  const onClickSignInButton = () => {
    track(EVENTS.COMPONENT_ACTION, {
      actionId: `bankruptcyProductPage.${CTATrackingTarget.LOGIN}`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
  };
  return (
    <a href="https://my.upsolve.org/login">
      <StyledButton onClick={onClickSignInButton} inverted>
        Sign In
      </StyledButton>
    </a>
  );
};

type StyledButtonProps = {
  inverted?: boolean;
};
const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  width: 166.667px;
  height: 40px;
  padding: 6.667px 10.667px;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 34.667px;
  border: 0.667px solid #d0d5dd;
  background: ${(props) => (props.inverted ? "white" : "#3c5dff")};
  color: ${(props) => (props.inverted ? "#3c5dff" : "white")};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.inverted ? "#EBEFFF" : "#324edb")};
  }

  @media screen and (max-width: 940px) {
    width: 240px;
    height: 44px;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 31px;
    font-size: 16px;
    line-height: 20px; /* 125% */
  }
  .inverted {
    color: #3c5dff;
    background-color: white;
    border: 0.667px solid #3c5dff;
  }
`;
const ProductPage = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  h1 {
    font-family: "Castoro";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    @media screen and (max-width: 940px) {
      font-size: 26px;
    }
  }
  h3 {
    font-size: 24px;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 940px) {
      font-size: 18px;
    }
  }
  p {
    /* Desktop/Body */
    font-size: 20px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: normal;
    margin: 0;
    @media screen and (max-width: 940px) {
      font-size: 16px;
    }
  }

  section {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .hide-mobile {
    display: block;
  }
  .hide-widescreen {
    display: none;
  }
  @media screen and (max-width: 940px) {
    .hide-mobile {
      display: none;
    }
    .hide-widescreen {
      display: block;
    }
  }

  .content {
    max-width: 1280px;
  }
  .hero {
    display: flex;
    height: 666px;
    flex-shrink: 0;
    background: linear-gradient(12deg, rgba(0, 0, 0, 0.5) 39.21%, rgba(0, 0, 0, 0) 56.46%), url(${familyFull}) no-repeat,
      lightgray -40.771px 10.159px / 110.684% 142.265%;

    background-size: cover;

    @media screen and (max-width: 440px) {
      display: inline-flex;
      height: 903px;
      align-items: flex-end;
      gap: 34px;
      padding: 42px 0;
      background: url(${familyMobile}) no-repeat, lightgray;
    }
    .hero__content {
      padding: 0px 66px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 34px;
      @media screen and (max-width: 940px) {
        padding: 37px 20px;
        text-align: center;
        align-items: center;
        justify-content: flex-end;
      }
      .hero__card {
        box-sizing: border-box;
        display: flex;
        padding: 26px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        border-radius: 10px;
        width: 440px;
        height: 300px;
        background: #fff;
        @media screen and (max-width: 940px) {
          align-items: center;
          gap: 20px;
          padding: 20px;
          width: 280px;
          height: auto;
        }
        .hero__card__buttons {
          display: flex;
          gap: 8px;
          align-items: center;
          @media screen and (max-width: 940px) {
            justify-content: center;
            flex-direction: column;
          }
        }
      }
      .hero__members-number {
        color: white;
        width: 100%;
      }
      .hero__badges-container {
        width: 100%;
        display: flex;
        justify-content: center;
        height: fit-content;
      }
      .hero__badges {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        gap: 20px;
        row-gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        @media screen and (max-width: 940px) {
          height: 234px;
          max-width: 280px;
        }
        & > a {
          &:hover {
            cursor: pointer;
          }
        }
        @media only screen and (max-width: 1534px) {
          justify-content: space-evenly;
        }
      }
    }
  }

  .how-it-works {
    height: 922px;
    background: #ede8da;
    display: flex;
    @media screen and (max-width: 940px) {
      background: #ffffff;
      height: auto;
    }
    .how-it-works__content {
      display: flex;
      flex: 1;
      gap: 14px;
      @media screen and (max-width: 940px) {
        gap: 32px;
      }
      .how-it-works__header-mobile {
        display: none;
      }
      .how-it-works__header-widescreen {
        display: block;
      }
      @media screen and (max-width: 940px) {
        flex-direction: column;
        align-items: center;
        padding: 42px 8px;
        .how-it-works__header-mobile {
          display: block;
          text-align: center;
          width: 240px;
        }
        .how-it-works__header-widescreen {
          display: none;
        }
      }

      .how-it-works__hand-mockup--container {
        display: flex;
        height: 100%;
        justify-content: center;
        flex: 1;
        align-items: flex-end;
        @media screen and (max-width: 940px) {
          flex-direction: column;
          background: #ede8da;
          border-radius: 8px;
          width: 290px;
        }
        .how-it-works__hand-mockup {
          background: url(${handMockup}) no-repeat;
          width: 672px;
          height: 772px;
          flex-shrink: 0;
          @media screen and (max-width: 940px) {
            background: url(${handMobile}) no-repeat;
            width: 290px;
            height: 290px;
          }
        }
      }
      .how-it-works__timeline-section {
        display: flex;
        flex: 1;
        margin-top: 86px;
        @media screen and (max-width: 940px) {
          margin-top: 0px;
        }
        .how-it-works__timeline {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: flex-start;
          gap: 40px;
          flex-wrap: wrap;
          justify-content: flex-start;
          max-width: 440px;
          @media screen and (max-width: 940px) {
            padding: 20px 26px;
            gap: 20px;
          }
          .how-it-works__timeline__step {
            display: flex;
            gap: 30px;
            height: 50px;
            align-items: flex-start;
            justify-content: flex-start;
            @media screen and (max-width: 940px) {
              gap: 13px;
              height: 36px;
            }
          }
        }
      }
    }
  }
  .trust {
    display: flex;
    background: #d9d9d9;
    padding: 60px 26.667px;
    box-sizing: border-box;
    @media screen and (max-width: 940px) {
      padding: 42px 20px;
    }
    .trust__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 29.333px;
      flex-shrink: 0;
      @media screen and (max-width: 940px) {
        gap: 20px;
      }
      .trust__content-reasons {
        text-align: left;
        max-width: 800px;
        font-weight: 300;
      }
      .trust__reviews {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 29.333px;
        flex-wrap: wrap;
        @media screen and (max-width: 940px) {
          gap: 20px;
        }
      }
    }
  }
  .faq {
    display: flex;

    .faq__content {
      flex: 1;
      display: flex;
      padding: 60px 66.667px 60px 111.333px;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 940px) {
        padding: 42px 20px;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .video {
    display: flex;
    flex: 1;
    background: #ede8da;
    .video__content {
      padding: 60px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      gap: 44px;
      align-items: center;
    }
  }
  .blue {
    color: #3c5dff;
  }
`;
